<template>
  <b-modal
    v-if="selected_bn && selected_bn.details"
    id="copy-node-modal"
    v-model="localShowBoolean"
    :title="`Copy ${selected_bn.details.name}`"
    size="lg"
    :ok-title="creating ? 'Copying...' : 'Copy Behaviour Node'"
    :ok-disabled="creating || !isDestinationNodeSelected"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @show="getData"
    @hidden="updateShowBoolean"
    @ok.prevent="submitFunction"
  >
    <div>
      <b-form-group
        label="Behaviour Tree"
        label-for="bt-select"
      >
        <b-form-select
          id="bt-select"
          v-model="bt_selected"
          :options="bt_options"
          :select-size="7"
          @change="updateBNList"
        />
      </b-form-group>

      <b-form-group
        label="Copy after node"
        label-for="bn-select"
      >
        <b-form-select
          id="bn-select"
          v-model="bn_selected"
          :options="bn_options"
          :select-size="7"
        />
      </b-form-group>

      <hr>

      <div class="px-4 w-100 d-inline-flex justify-content-between">
        <b-form-checkbox
          id="ch-checkbox"
          v-model="withChildren"
          :checked="withChildren"
          name="ch-checkbox"
          switch
        >
          Include Children
        </b-form-checkbox>
        <b-form-checkbox
          id="reqs-checkbox"
          v-model="withReqs"
          :checked="withReqs"
          name="reqs-checkbox"
          switch
        >
          Include Requirements
        </b-form-checkbox>
        <b-form-checkbox
          id="iss-checkbox"
          v-model="withIssues"
          :checked="withIssues"
          name="iss-checkbox"
          switch
        >
          Include Issues
        </b-form-checkbox>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default {
  name: 'CopyBehaviourNodeModal',
  props: {
    showBoolean: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    bt_selected: '',
    bn_selected: '',
    bn_options: [],
    withChildren: true,
    withReqs: true,
    withIssues: true,
    creating: false,
    isDestinationNodeSelected: false,
    localShowBoolean: false,
  }),
  computed: {
    ...mapState({
      selected_bn: state => state.behaviours.selectedBehaviourNode,
      selected_bt: state => state.behaviours.selectedBehaviourTree,
      behaviourViews: state => state.behaviours.behaviourViews,
    }),
    bt_options() {
      return this.behaviourViews.map(x => ({
        value: x.id,
        html: x.name,
      }))
    },
    // bn_options() {
    //   return this.selected_bt.nodes.map(x => ({
    //     value: x.id,
    //     html: x.name,
    //   }))
    // },
  },
  watch: {
    showBoolean(newVal) {
      this.localShowBoolean = newVal
    },
    bn_selected(newVal) {
      this.isDestinationNodeSelected = newVal !== ''
    },
    selected_bt: {
      handler(newVal) {
        this.setBNOptions(newVal.nodes)
      },
      immediate: true,
    },
  },
  methods: {
    getData() {
      this.withChildren = true
      this.withReqs = true
      this.withIssues = true
      this.bt_selected = this.selected_bt.id || ''
      this.$store.dispatch('behaviours/getAllBehaviourTrees')
    },
    updateBNList(bt) {
      // Uses the No Select call as it needs to just fetch the data but not change the BT in the background
      // this.$store.dispatch('behaviours/getBTNoSelection', bt)
      //   .then(data => {
      //     console.log('Component test', data)
      //   })
      const { modelId } = this.$router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      coreService
        .get(`/v1/legacy/behaviour/get_bt/${bt}`, config)
        .then(({ data }) => {
          // console.log({ data })
          this.setBNOptions(data.nodes)
          this.bn_selected = ''
        })
        .catch(e => console.error(e))
    },
    setBNOptions(nodeArray) {
      this.bn_options = nodeArray.map(x => ({
        value: x.id,
        html: x.name,
      }))
    },
    submitFunction() {
      this.creating = true
      const fields = {
        source: this.selected_bn.details.id,
        target: this.bn_selected,
        bt: this.bt_selected,
        rel_type: 'sequence',
        with_children: this.withChildren,
        with_requirements: this.withReqs,
        with_issues: this.withIssues,
      }
      // console.log(this.$router, this.$route)
      // Determine which view the duplicate will open after creation
      const pathData = {
        name: 'model_behaviour_tree_focus',
        params: {
          behaviourTreeId: this.bt_selected,
          modelId: this.$route.params.modelId,
        },
        query: {
          focus: this.bn_selected,
        },
        replace: false,
      }
      if (this.$route.name === 'joint_mbse_tree') {
        pathData.name = 'joint_mbse_tree_focus'
      } else if (this.$route.name === 'joint_mbse_tree_focus') {
        pathData.name = 'joint_mbse_tree_focus'
        pathData.replace = true
      }
      // Create and navigate
      coreService.post('/v1/legacy/behaviour/copy_node', fields).then(({ data }) => {
        this.$store.dispatch('behaviours/selectBehaviourTree', this.bt_selected)
          .then(() => {
            this.creating = false
            this.$bvModal.hide('copy-node-modal')
            this.$router.push(pathData)
            this.$emit('duplicated')
          })
      })
        .catch(error => {
          console.error(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to Copy Behaviour Node(s)',
              text: `An error occurred when attempting to copy the behaviour node(s).
              Server returned Status ${error}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.creating = false
        })
    },
    updateShowBoolean() {
      this.$emit('update-bool', this.localShowBoolean)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.modal-footer-behaviour {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>

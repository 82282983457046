<template>
  <b-modal
    id="allocate-functions-modal"
    title="Associate Functions"
    size="lg"
    @ok="submit"
    @shown="shown"
  >
    <!-- Available Items -->
    <b-row>
      <b-col>
        <label for="allItems">
          <b-badge class="bg-info">{{ allItems.length }}</b-badge> :
          Available Functions
        </label>
        <div v-if="loadingAll" class="text-center">
          <b-spinner class="mt-3" variant="primary" label="Loading..." />
        </div>
        <b-form-select
          v-else
          id="allItems"
          v-model="selectedAllItems"
          :options="allItems"
          :select-size="6"
          multiple
        />
      </b-col>
    </b-row>

    <!-- Association and Un-association buttons -->
    <b-row class="mx-auto">
      <b-col>
        <b-button-group class="w-100">
          <!-- Remove Association -->
          <b-button
            variant="flat-danger"
            :disabled="selectedAssociatedItems.length === 0"
            @click="remove"
          >
            <feather-icon icon="ArrowUpIcon" size="24" />
          </b-button>

          <!-- Add Association -->
          <b-button
            variant="flat-success"
            :disabled="selectedAllItems.length === 0"
            @click="add"
          >
            <feather-icon icon="ArrowDownIcon" size="24" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <!-- Associated Items -->
    <b-row class="mb-2">
      <b-col>
        <label for="associatedItems">
          <b-badge class="bg-danger">{{ associatedItems.length }}</b-badge> :
          Associated Functions
        </label>
        <div v-if="loadingAssociated" class="text-center">
          <b-spinner class="mt-3" variant="primary" label="Loading..." />
        </div>
        <b-form-select
          v-else
          id="associatedItems"
          v-model="selectedAssociatedItems"
          :options="associatedItems"
          multiple
          :select-size="6"
        />
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="success" :disabled="loadingSubmit" @click="ok()">
        <span v-if="loadingSubmit">
          <b-spinner small type="grow" />
          Associating...
        </span>
        <span v-else>Associate Functions</span>
      </b-button>
      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import coreService from '@/libs/api-services/core-service'

export default {
  props: {
    focusedEntityId: {
      /* Entity to associate/de-associate Items */
      required: true,
      type: String,
    },
    urlAssociated: {
      /* API endpoint to GET and POST Items for this entity type */
      required: true,
      type: String,
    },
  },
  data: () => ({
    allItems: [],
    selectedAllItems: [],

    associatedItems: [],
    selectedAssociatedItems: [],

    loadingAll: true,
    loadingSubmit: false,
    loadingAssociated: true,
  }),
  methods: {
    shown() {
      this.fetchAll()
      this.fetchAssociated()
      this.loadingSubmit = false
    },
    fetchAll() {
      this.loadingAll = true
      const params = { model: this.$store.state.model.id }
      coreService.get('/v1/legacy/domain_model/functions', { params })
        .then(({ data }) => {
          this.allItems = data.map(item => ({
            value: item.id,
            text: item.name,
          }))

          // Remove already associated elements from the allItems array
          this.associatedItems.forEach((elementA, indexA) => {
            this.allItems.forEach((elementB, indexB) => {
              if (elementA.value === elementB.value) {
                this.allItems.splice(indexB, 1)
              }
            })
          })

          this.loadingAll = false
          // console.debug('[AssociateTestCasesModal] fetchAll returned:')
          // console.debug(this.allItems)
        })
        .catch(r => {
          this.loadingAll = true
          console.error(`[AssociateTestCasesModal] fetchAll failed - ${r}`)
        })
    },
    fetchAssociated() {
      const params = { model: this.$store.state.model.id }
      const apiService = this.urlAssociated.includes('v1//legacy') ? coreService : this.$http
      apiService.get(this.urlAssociated, { params })
        .then(({ data }) => {
          console.log('Associated functions: ', data)
          this.associatedItems = data.map(item => ({
            value: item.id,
            text: item.name,
          }))

          this.loadingAssociated = false
          // console.debug('[AssociateTestCasesModal] fetchAssociated returned:')
          // console.debug(this.associatedItems)
        })
        .catch(r => {
          this.loadingAssociated = true
          console.error(`[AssociateTestCasesModal] fetchAssociated failed - ${r}`)
        })
    },
    add() {
      this.selectedAllItems.forEach((elementA, indexA) => {
        this.allItems.forEach((elementB, indexB) => {
          if (elementA === elementB.value) {
            this.allItems.splice(indexB, 1)
            this.associatedItems.push(elementB)
          }
        })
      })
      this.selectedAllItems = []
    },
    remove() {
      this.selectedAssociatedItems.forEach((elementA, indexA) => {
        this.associatedItems.forEach((elementB, indexB) => {
          if (elementA === elementB.value) {
            this.associatedItems.splice(indexB, 1)
            this.allItems.push(elementB)
          }
        })
      })
      this.selectedAssociatedItems = []
    },
    submit(evt) {
      evt.preventDefault()
      this.loadingSubmit = true

      const payload = {
        model: this.$store.state.model.id,
        fns: this.associatedItems.map(x => x.value),
        overwrite: true,
      }
      const apiService = this.urlAssociated.includes('v1//legacy') ? coreService : this.$http
      apiService.post(this.urlAssociated, payload)
        .then(() => {
          this.loadingSubmit = false
          this.$store.dispatch('behaviours/selectBehaviourNode', this.focusedEntityId)
          this.$bvModal.hide('allocate-functions-modal')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Functions associated',
              text: 'Successfully associated Functions with the BehaviourNode',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(r => {
          this.loadingSubmit = false
          console.error(`[AssociateFunctionModal] submit failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to associate Functions',
              text: `An error occurred when attempting to associate Functions with the BehaviourNode.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<template>
  <b-modal
    id="instantiateInterfaceModal"
    v-model="localShowBoolean"
    title="Select Interface to Instantiate"
    size="lg"
    @ok="postSelection"
    @show="getData"
    @hidden="updateShowBoolean"
  >
    <b-form-input
      v-model="query"
      size="lg"
      type="search"
      placeholder="Search..."
    />
    <b-form-select
      v-model="value"
      :options="makeSetArray(visibleOptions)"
      :select-size="6"
      size="lg"
    />
    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="primary" @click="ok()">
        Select
      </b-button>

      <b-button @click="cancel()">
        Cancel
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'InterfaceSelect',
  props: {
    showBoolean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: '',
      value: '',
      interfaces: [],
      localShowBoolean: false,
    }
  },
  computed: {
    options() {
      return this.interfaces.map(x => ({
        value: x.id,
        text: x.name,
      }))
    },
    visibleOptions() {
      return this.interfaces.map(x => ({
        value: x.id,
        text: `${x.display_id} - ${x.name}`,
      }))
        .filter(item => String(item)
          .toLowerCase()
          .includes(this.query.toLowerCase()))
    },
  },
  watch: {
    showBoolean(newVal) {
      this.localShowBoolean = newVal
    },
  },
  methods: {
    getData() {
      const modelId = this.$store.state.model.id
      this.$http.get('/v1/legacy/interfaces', { params: { model: modelId } })
        .then(({ data }) => {
          console.log('Interfaces loaded: ', data)
          this.interfaces = data
        })
    },
    makeSetArray: arr => [...new Set(arr)],
    postSelection(evt) {
      evt.preventDefault()
      const selected = this.value
      console.log('Selected: ', selected)
      this.$emit('input', selected)
    },
    updateShowBoolean() {
      this.$emit('update-bool', this.localShowBoolean)
    },
  },
}
</script>

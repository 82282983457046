<template>
  <b-modal
    id="timed-bn-modal"
    v-model="localShowBoolean"
    title="Set Timing Constraints for a Behaviour"
    size="lg"
    ok-title="Set Timing Constraints"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    @shown="getData"
    @ok="updateBehaviour"
    @cancel="resetForm"
    @hidden="updateShowBoolean"
  >
    <div>
      <h4>Essential</h4>
      <div class="d-inline-flex w-100 justify-content-between">
        <b-form-group label="Assertion" label-for="timer-property" class="w-25 mr-50">
          <b-form-select
            id="timer-property"
            v-model="comparators[0]"
            :options="comparatorOptions"
            required
          />
        </b-form-group>
        <b-form-group label="Constraint" label-for="constraint" class="w-50 mr-50">
          <b-form-input
            id="constraint"
            v-model="constraints[0]"
            placeholder="Performance requirement - e.g. 3 seconds"
            type="text"
            required
          />
        </b-form-group>
        <b-form-group label="Conditions" label-for="timer-condition">
          <b-form-input id="timer-condition" v-model="conditions[0]" list="source-list" />

          <datalist id="source-list">
            <option>Under <i>Peak Load</i></option>
            <option>Under <i>Nominal Load</i></option>
            <option>Under <i>Design Load</i></option>
          </datalist>
        </b-form-group>
      </div>
    </div>

    <hr>

    <div>
      <h4>Very Important</h4>
      <div class="d-inline-flex w-100 justify-content-between">
        <b-form-group label="Assertion" label-for="timer-property" class="w-25 mr-50">
          <b-form-select
            id="timer-property"
            v-model="comparators[1]"
            :options="comparatorOptions"
            required
          />
        </b-form-group>
        <b-form-group label="Constraint" label-for="constraint" class="w-50 mr-50">
          <b-form-input
            id="constraint"
            v-model="constraints[1]"
            placeholder="Performance requirement - e.g. 3 seconds"
            type="text"
            required
          />
        </b-form-group>
        <b-form-group label="Conditions" label-for="timer-condition">
          <b-form-input id="timer-condition" v-model="conditions[1]" list="source-list" />

          <datalist id="source-list">
            <option>Under <i>Peak Load</i></option>
            <option>Under <i>Nominal Load</i></option>
            <option>Under <i>Design Load</i></option>
          </datalist>
        </b-form-group>
      </div>
    </div>

    <hr>

    <div>
      <h4>Important</h4>
      <div class="d-inline-flex w-100 justify-content-between">
        <b-form-group label="Assertion" label-for="timer-property" class="w-25 mr-50">
          <b-form-select
            id="timer-property"
            v-model="comparators[2]"
            :options="comparatorOptions"
            required
          />
        </b-form-group>
        <b-form-group label="Constraint" label-for="constraint" class="w-50 mr-50">
          <b-form-input
            id="constraint"
            v-model="constraints[2]"
            placeholder="Performance requirement - e.g. 3 seconds"
            type="text"
            required
          />
        </b-form-group>
        <b-form-group label="Conditions" label-for="timer-condition">
          <b-form-input id="timer-condition" v-model="conditions[2]" list="source-list" />

          <datalist id="source-list">
            <option>Under <i>Peak Load</i></option>
            <option>Under <i>Nominal Load</i></option>
            <option>Under <i>Design Load</i></option>
          </datalist>
        </b-form-group>
      </div>
    </div>

    <hr>

    <div>
      <h4>Desirable</h4>
      <div class="d-inline-flex w-100 justify-content-between">
        <b-form-group label="Assertion" label-for="timer-property" class="w-25 mr-50">
          <b-form-select
            id="timer-property"
            v-model="comparators[3]"
            :options="comparatorOptions"
            required
          />
        </b-form-group>
        <b-form-group label="Constraint" label-for="constraint" class="w-50 mr-50">
          <b-form-input
            id="constraint"
            v-model="constraints[3]"
            placeholder="Performance requirement - e.g. 3 seconds"
            type="text"
            required
          />
        </b-form-group>
        <b-form-group label="Conditions" label-for="timer-condition">
          <b-form-input id="timer-condition" v-model="conditions[3]" list="source-list" />

          <datalist id="source-list">
            <option>Under <i>Peak Load</i></option>
            <option>Under <i>Nominal Load</i></option>
            <option>Under <i>Design Load</i></option>
          </datalist>
        </b-form-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'EditTimingConstraint',
  props: {
    selectedBNs: {
      type: Array,
      required: true,
    },
    showBoolean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comparators: ['<=', '<=', '<=', '<='],
      constraints: ['', '', '', ''],
      conditions: ['', '', '', ''],
      start: null,
      end: null,
      comparatorOptions: [
        { value: '<=', text: '<=' },
        { value: '<', text: '<' },
        { value: '=', text: '=' },
        { value: '>=', text: '>=' },
        { value: '>', text: '>' },
        { value: '!=', text: '!=' },
      ],
      localShowBoolean: false,
    }
  },
  watch: {
    showBoolean(newVal) {
      this.localShowBoolean = newVal
    },
  },
  methods: {
    getData() {
      // Get the start and end nodes
      if (this.selectedBNs.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        this.start = this.selectedBNs[0]
        this.end = this.selectedBNs[this.selectedBNs.length - 1]
      }
    },
    updateBehaviour(evt) {
      evt.preventDefault()
      const payload = {
        model: this.$store.state.model.id,
        start: this.start,
        end: this.end,
        comparators: this.comparators,
        constraints: this.constraints,
        conditions: this.conditions,
      }
      this.$http.post('/v1/legacy/behaviour/nodes/timing', payload)
        .then(({ data }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated behaviour timing',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('timed-bn-modal')
          this.resetForm()
          this.$emit('updated-timing', data)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'An error occurred when trying to update the behaviour timing.',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {})
    },
    updateShowBoolean() {
      this.$emit('update-bool', this.localShowBoolean)
    },
    resetForm() {
      this.comparators = ['<=', '<=', '<=', '<=']
      this.constraints = ['', '', '', '']
      this.conditions = ['', '', '', '']
    },
  },
}
</script>
